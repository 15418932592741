<template>
    <div class="content">
        <left-bar></left-bar>
        <div class="right-box">
            <div>
                <el-breadcrumb separator="/">
                    <el-breadcrumb-item :to="{ path: '/personalCenter/personalCenter' }">个人中心</el-breadcrumb-item>
                    <el-breadcrumb-item>编辑</el-breadcrumb-item>
                </el-breadcrumb>
            </div>

            <!-- 基本信息 -->
            <div class="right-box__top">
                <div class="right-box-bar mt30">
                    <span>基本信息</span>
                    <el-button plain size="small" style="width:68px;padding:7px 0" @click="toNavBack">返回</el-button>
                </div>

                <div style="padding:40px 20px;" class="flex-row-start ">
                    <div style="margin-right:72px;flex-shrink:0">
                        <el-image :src="formData.headPortrait" :preview-src-list="[formData.headPortrait]"
                            class="head-image"></el-image>
                        <div>
                            <el-upload accept=".png,.jpg,.jpeg" :limit="1" action="#" :http-request="toUploadFile"
                                :headers="headers" :before-upload="onBeforeUpload" :show-file-list="false">
                                <el-button plain size="small"
                                    style="width:68px;background:#fff;color:#2180EF;border-color:#fff;padding:7px 0"
                                    type="primary">点击更换</el-button>
                            </el-upload>
                        </div>
                    </div>
                    <div style="text-align:left;">
                        <el-form :model="formData" label-position="left">
                            <el-form-item label="昵称" label-width="72px">
                                <el-input v-model="formData.userName" placeholder="请输入昵称" style="width:200px"></el-input>
                            </el-form-item>
                            <el-form-item label="性别" label-width="72px">
                                <el-radio v-model="formData.sex" :label="1">男</el-radio>
                                <el-radio v-model="formData.sex" :label="2">女</el-radio>
                            </el-form-item>
                            <el-form-item label="生日" label-width="72px">
                                <el-date-picker v-model="formData.birthday" type="date" style="width:400px"
                                    placeholder="选择日期" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd HH:mm:ss"
                                    :picker-options="prickerOptions">
                                </el-date-picker>
                            </el-form-item>
                            <el-form-item label="地区" label-width="72px">
                                <el-cascader v-model="formData.location" :options="addressDataNew" ref="cascader"
                                    style="width:400px" :props="addressProps" class="width360"></el-cascader>

                            </el-form-item>
                            <el-form-item label="" label-width="72px">
                                <el-button type="primary" style="width:160px;background:#2180EF;padding:12px 0;"
                                    @click="onSubmit" v-loading="loading">保存</el-button>
                                <el-button type="default"
                                    style="width:160px;border-color:#2180EF;color:#2180EF;padding:12px 0;"
                                    @click="toNavBack">取消</el-button>
                            </el-form-item>
                        </el-form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from "vuex"
import leftBar from '@/views/personalCenter/components/left-bar/left-bar'
import { updateUserInfo } from "@/api/personalCenter"
import Setting from "@src/setting.js"
import uploadToOss from "@/utils/uploadToOss"
export default {
    components: {
        leftBar
    },
    data() {
        return {
            formData: {
                userName: "",
                location: [],
                birthday: "",
                sex: null,
                headPortrait: "",
            },
            prickerOptions: {
                disabledDate: (time) => {
                    return !(time.getTime() < Date.now())
                },
            },
            addressProps: {
                expandTrigger: "hover",
                value: "label",
            },
            loading: false
        }
    },
    computed: {
        ...mapState("account", ["userInfos", "token"]),
        ...mapState("address", ["addressDataNew"]),
        headers() {
            var token = this.token;
            var headers = Setting.apiHeaders
            headers["X-Litemall-Admin-Token"] = token
            return headers
        },
    },

    async created() {
        document.title = "邦建通-个人中心";
        await this.getNewUserInfo()
        let userInfos = this.userInfos
        let location = []
        if (userInfos.provinceName && userInfos.regionName) {
            location = [userInfos.provinceName, userInfos.cityName || "直辖市", userInfos.regionName]
        }
        this.formData = {
            userName: userInfos.userName,
            sex: userInfos.sex || 1,
            birthday: userInfos.birthday,
            headPortrait: userInfos.headPortrait,
            location,
        }
    },
    mounted() {

    },

    methods: {
        ...mapActions('account', ['getNewUserInfo']),

        toNavBack() {
            this.$router.replace("/personalCenter/personalCenter")
        },

        async onSubmit() {
            try {
                this.loading = true


                let location = this.formData.location

                let provinceName = ""
                let cityName = ""
                let regionName = ""

                if (location?.length) {
                    provinceName = location[0]
                    cityName = location[1] || "直辖市"
                    regionName = location[2]
                }

                await updateUserInfo({
                    ...this.formData,
                    provinceName,
                    cityName,
                    regionName,
                    birthday: this.formData.birthday
                })
                this.$store.dispatch("account/getNewUserInfo")
                if (window?.opener) {
                    window.opener.postMessage("updateUserInfo", "*")
                }
                this.$message.success("保存成功")
                this.toNavBack()
            } catch (err) {

            } finally {
                this.loading = false
            }
        },
        // 文件上传之前的回调函数
        async onBeforeUpload(file) {
            var fileExtension = file.name.substring(file.name.lastIndexOf('.') + 1);
            if (!/^png|jpg|jpeg$/.test(fileExtension)) {
                this.$message.warning(`仅支持格式为png、jpg、jpeg的格式上传!`)
                return Promise.reject()
            }
            const fileSize = 2 * 1024 * 1024;
            if (fileSize < file.size) {
                this.$message.warning(`文件大小不能超过2MB`)
                return Promise.reject()
            }

            return true
        },
        async toUploadFile(data) {
            try {
                let res = await this.getFileUrl(data.file)
                this.formData.headPortrait = res
            } catch (err) {
                // this.$message.error("上传文件错误")
            }
        },
        getFileUrl(file) {
            return new Promise(async (resolve, reject) => {
                try {
                    let res = await uploadToOss.uploadFile(file, "image")
                    resolve(res)
                } catch (err) {
                    reject(err)
                }
            })
        },

    }
}
</script>

<style scoped lang="less">
.flex-row-start {
    display: flex;
    align-items: flex-start;
}

.mt30 {
    margin-top: 30px;
}

.content {
    background: #F8F9FB;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding: 20px;
    font-family: Source Han Sans CN-Regular;
    color: #212121;
    font-size: 16px;
}

.right-box {
    .right-box__top {
        width: 1260px;
        background: #fff;
        margin-bottom: 160px;

        .head-image {
            width: 70px;
            height: 70px;
            border-radius: 70px;
            margin-bottom: 10px;
        }
    }

    .right-box__bottom {
        margin-top: 20px;
        background: #fff;
    }

    .right-box-bar {
        padding: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #EDF4FF;

        span {
            font-weight: 600;
        }
    }


}
</style>